import React, { Component } from 'react';
import { connect } from 'react-redux';

// Quick and easy state displayer. Give it a state, it will give you a state label.
// function = blue state
// approve = green state
// wait = yellow state
// warning = red state
export const stateMapper = {
    Registered: {
        text: 'Registrerad',
        class: 'function',
    },
    Reserved: {
        text: 'Hantering pågår',
        class: 'function',
    },
    Settled: {
        text: 'Avräkning',
        class: 'approve',
    },
    Paid: {
        text: 'Betald',
        class: 'approve',
    },
    AcceptedForSettlement: {
        text: 'Godkänd',
        class: 'approve',
    },
    ReadyPaid: {
        text: 'Betald (klar)',
        class: 'approve',
    },
    ReadyRepurchased: {
        text: 'Återdebiterad',
        class: 'approve',
    },
    AcceptedForDelivery: {
        text: 'Mottagare godkänt',
        class: 'approve',
    },
    PendingRecipientDeliveryAcceptance: {
        text: 'Inväntar leveransgodkännande',
        class: 'wait',
    },
    Reminder1: {
        text: 'Påminnelse',
        class: 'wait',
    },
    DebtCollector: {
        text: 'Inkasso',
        class: 'warning',
    },
    OrderToPayApplication: {
        text: 'Skickad till kronofogden',
        class: 'warning',
    },
    OrderToPayWarning: {
        text: 'Varning om kronofogden',
        class: 'warning',
    },
    PendingRepurchasing: {
        text: 'Återförd',
        class: 'warning',
    },
    Rejected: {
        text: 'Avvisad',
        class: 'warning',
    },
    ReadyRepurchasedPaid: {
        text: 'Återdebiterad (betald)',
        class: 'approve',
    },
};
class InvoiceState extends Component {
    findStateInfo(state) {
        return (
            stateMapper[state] || {
                text: 'Okänd status',
                class: '',
            }
        );
    }
    render() {
        const state = this.findStateInfo(this.props.state);
        const classNames = this.props.disabled ? `button button--chip button--disabled button--${state.class}` : `button button--chip button--${state.class}`;
        return <span className={classNames}>{state.text}</span>;
    }
}

const mapStateToProps = (state) => {
    return {
        router: state.router,
    };
};

InvoiceState = connect(mapStateToProps, {})(InvoiceState);

export default InvoiceState;
